import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from "dayjs";
function tick() {
    const pStyle={
        fontSize:"20px"
    }
    const dayjs = require('dayjs');
    const utc = require('dayjs/plugin/utc');
    const timezone = require('dayjs/plugin/timezone');
    dayjs.extend(utc); dayjs.extend(timezone);

    let m = dayjs();
    const element = (
        <div>
            <h1>Hello, world!</h1>
            <p style={pStyle}><b>Local:</b> {m.tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')}</p>
            <p style={pStyle}><b>UTC:</b> {m.tz('Etc/GMT').format('YYYY-MM-DD HH:mm:ss')}</p>
            <p style={pStyle}><b>Beijing:</b> {m.tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}</p>
            <p style={pStyle}><b>Tokyo:</b> {m.tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')}</p>
            <p style={pStyle}><b>London:</b> {m.tz('Europe/London').format('YYYY-MM-DD HH:mm:ss')}</p>
            <p style={pStyle}><b>Paris:</b> {m.tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss')}</p>
            <p style={pStyle}><b>Los Angeles:</b> {m.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss')}</p>
            <p style={pStyle}><b>New York:</b> {m.tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</p>
            <p style={pStyle}><b>Sydney:</b> {m.tz('Australia/Sydney').format('YYYY-MM-DD HH:mm:ss')}</p>
            <p style={pStyle}><b>AoE:</b> {m.tz('Etc/GMT+12').format('YYYY-MM-DD HH:mm:ss')}</p>
        </div>
    );
    ReactDOM.render(
        element,
        document.getElementById('example')
    );
}

setInterval(tick, 1000);